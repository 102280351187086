.masthead {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  text-align: center;
}

.masthead__link {
	$primary-color: #17BB7A;

	display: inline-block;
	color: $primary-color;
	transition: color 0.2s;

	&:hover {
		color: saturate(darken($primary-color, 15%), 50%);
	}

	& + & {;
		margin-left: 1em;
	}

	&:last-of-type {
    display: block;
    margin-top: 1em;
		margin-left: 0;
	}
}

.masthead__icon {
  @include icon;
  font-size: 6em;
}
